import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function HeroBanner ( props ) {

    const { image } = props

    return (
        <section className="hero-banner">
            <div className="hero-banner__wrapper">
                <div className="hero-banner__container">
                    <GatsbyImage
                        image={ image.localFile.childImageSharp.gatsbyImageData }
                        loading="eager"
                        layout="fullWidth"
                        width="100%"
                        className="hero-banner__image"
                        alt="" />
                </div>
            </div>
        </section>
    )
}

HeroBanner.propTypes = {
    image: PropTypes.object.isRequired
};