import React from 'react'
import PropTypes from 'prop-types'

import RoomImages from '../RoomImages/RoomImages'

export default function Rooms ( props ) {

    const { message, images } = props

    return (
        <section className="rooms">
            <div className="rooms__wrapper wrapper">
                <div className="rooms__container rooms__container--info">
                    <p className="rooms__text">{ message }</p>
                    <a className="rooms__button" href="mailto:info@thehexagon.org.uk">Get in touch</a>
                </div>
                <div className="rooms__container rooms__container--images">
                    <RoomImages images={ images } />
                </div>
            </div>
        </section>
    )
}

Rooms.propTypes = {
    message: PropTypes.string.isRequired
};