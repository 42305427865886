import React from 'react'
import PropTypes from 'prop-types'

export default function ContactBanner ( props ) {

    const { message } = props

    return (
        <section className="contact-banner">
            <div className="contact-banner__wrapper wrapper">
                <p className="contact-banner__text">{ message }</p>
                <a className="contact-banner__button" href="mailto:info@thehexagon.org.uk">Get in touch</a>
            </div>
        </section>
    )
}

ContactBanner.propTypes = {
    message: PropTypes.string.isRequired
};