import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/SEO/Seo'

import HeroBanner from '../components/HeroBanner/HeroBanner'
import Events from '../components/Events/Events'
import Rooms from '../components/Rooms/Rooms'
import AboutUs from '../components/AboutUs/AboutUs'
import ContactInfo from '../components/ContactInfo/ContactInfo'
import ContactBanner from '../components/ContactBanner/ContactBanner'

export default function Home({ data: { strapiHomepage, allStrapiCalendar }, location }) {

    return (
        <>
            <Seo
                title={ strapiHomepage?.seo?.metaTitle }
                description={ strapiHomepage?.seo?.metaDescription }
                keywords={ strapiHomepage?.seo?.keywords }
                pathname={ location.pathname } />

            <HeroBanner image={ strapiHomepage.heroImage } />
            <AboutUs statement={ strapiHomepage.aboutUsStatement } text={ strapiHomepage.aboutUsInfo.data.aboutUsInfo } />
            <ContactBanner message={ strapiHomepage.getInTouchBanner } />
            <Events events={ allStrapiCalendar.nodes } />
            <Rooms message={ strapiHomepage.roomsHeading } images={ strapiHomepage.roomImages } />
            <ContactInfo />
        </>
    )
}

export const query = graphql`
    query homePage {
        strapiHomepage {
            heroImage {
                localFile {
                    url
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            roomsHeading
            roomImages {
                caption
                localFile {
                    url
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            aboutUsStatement
            getInTouchBanner
            aboutUsInfo {
                data {
                    aboutUsInfo
                }
            }
            seo {
                metaTitle
                metaDescription
                keywords
            }
        }
        allStrapiCalendar(sort: {fields:startDate, order:ASC}) {
            nodes {
                strapi_id
                startDate
                endDate
                activity {
                    strapi_id
                    activityName
                    additionalInfo
                    contactName
                    contactNumber
                    contactEmail
                    privateGroup
                }
            }
          }
    }
`