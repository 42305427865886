import React, { forwardRef } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const ProductThumbnail = forwardRef( ( props,ref ) => {

    const { isSelected, image, index, handleThumbnailClick } = props

    return (
        <li className="room-images__slide" ref={ ref }>
            <div className="room-images__thumbnail-wrapper">
                <button
                    className={`room-images__thumbnail-container ${ isSelected ? 'active' : '' }`}
                    onClick={ () => handleThumbnailClick( index, image ) }>
                    <GatsbyImage image={ image.localFile.childImageSharp.gatsbyImageData } className="room-images__thumbnail-image" objectFit="contain" alt={ image.caption } />
                </button>
            </div>
        </li>
    )
} )

export default ProductThumbnail