import React, { useEffect } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import PlusIcon from '../../images/svgs/plus-icon.svg'
import ArrowRight from '../../images/svgs/arrow-right.svg'
import ArrowLeft from '../../images/svgs/arrow-left.svg'

export default function RoomModal ( props ) {

    const { state, modalActive, toggleImageModal, scrollNext, scrollPrev, totalImages } = props

    useEffect(() => {

        // If the user presses the escape key, close the modal
        const handleUserKeyPress = e => {

            if ( e.keyCode === 27 && modalActive ) {
                toggleImageModal()
            }
        }

        window.addEventListener( 'keydown', handleUserKeyPress )

        return () => {
            window.removeEventListener( 'keydown', handleUserKeyPress )
        }

    }, [ modalActive, toggleImageModal ])

    return (
        <div className={`room-images__modal ${ modalActive ? 'active' : '' }`}>
            <div className="room-images__modal-container">
                <PlusIcon className="room-images__modal-close" onClick={ () => toggleImageModal() } />
                <GatsbyImage image={ state.mainImage.localFile.childImageSharp.gatsbyImageData } className="room-images__modal-image" alt={ state.mainImage.caption } />
                <span className="room-images__image-caption">{ state.mainImage.caption }</span>

                <ArrowLeft
                    className={`room-images__modal-button room-images__modal-button--prev ${ !state.currentIndex ? 'disabled' : '' }`}
                    onClick={ () => scrollPrev() }
                    />

                <ArrowRight
                    className={`room-images__modal-button room-images__modal-button--next ${ state.currentIndex + 1 === totalImages ? 'disabled' : '' }`}
                    onClick={ () => scrollNext() }
                />
            </div>
        </div>
    )
}