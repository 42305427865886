import React from 'react'
import GoogleMapReact from 'google-map-react';

import mapStyle from './mapStyle'

export default function Map () {

    const eyePosition = {
        center: {
            lat: 52.3202,
            lng: 1.1462
        },
        zoom: 16.9
    }

    return (
        // Important! Always set the container height explicitly
        <div className="map">
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.GOOGLE_MAP_KEY }}
                options={{ styles: mapStyle }}
                defaultCenter={eyePosition.center}
                defaultZoom={eyePosition.zoom} />
        </div>
    )
}