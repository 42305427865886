import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

import Anchor from '../Anchor/Anchor'

export default function AboutUs ( props ) {

    const { statement, text } = props

    return (
        <section className="about-us wrapper">
            <Anchor anchorId="about" />
            <div className="about-us__wrapper wrapper">
                <h2 className="about-us__heading">About The <span className="">Hexagon</span></h2>
                <div className="about-us__content-container">
                    <p className="about-us__statement">{ statement }</p>
                    {/* <p className="about-us__text">{ text }</p> */}
                    <ReactMarkdown
                        className="about-us__text"
                        linkTarget="_blank"
                        children={ text } />
                </div>
            </div>
        </section>
    )
}

AboutUs.propTypes = {
    statement: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};