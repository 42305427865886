import React from 'react'

import Anchor from '../Anchor/Anchor'
import Map from '../Map/Map'

import BlossomLogo from '../../images/svgs/blossom-logo.svg'
import DreamOnLogo from '../../images/svgs/dream-on-logo.svg'

export default function ContactInfo () {

    return (
        <section className="contact-info">
            <Anchor anchorId="contact" />
            <h2 className="contact-info__heading">Get in touch</h2>
            <div className="contact-info__wrapper wrapper">
                <div className="contact-info__container">

                    <div className="contact-info__info-container">
                        <h3 className="contact-info__header">
                            <a href="mailto:info@thehexagon.org.uk" className="contact-info__link">info@thehexagon.org.uk</a>
                        </h3>
                        <span className="contact-info__sub-heading">Email Address</span>
                    </div>

                    <div className="contact-info__info-container">
                        <h3 className="contact-info__header">
                        <a href="tel:01379678483" className="contact-info__link">01379 678483</a>
                        </h3>
                        <span className="contact-info__sub-heading">Telephone</span>
                    </div>

                    <div className="contact-info__info-container">
                        <h3 className="contact-info__header">The Hexagon</h3>
                        <h3 className="contact-info__header">Eye, Suffolk</h3>
                        <h3 className="contact-info__header">IP23 7AF</h3>
                        <span className="contact-info__sub-heading">Address</span>
                    </div>

                    <div className="contact-info__info-container">
                        <div className="contact-info__logo-container">
                            <a className="contact-info__logo-link" href="https://dream-on.co.uk/beauty" target="_blank" rel="noreferrer">
                                <DreamOnLogo className="contact-info__logo contact-info__logo--dream-on" />
                            </a>
                            <a className="contact-info__logo-link" href="https://www.theblossomcharity.co.uk" target="_blank" rel="noreferrer">
                                <BlossomLogo className="contact-info__logo contact-info__logo--blossom" />
                            </a>
                        </div>
                        <span className="contact-info__sub-heading">Affiliates</span>
                    </div>
                </div>

                <Map />
            </div>
        </section>
    )
}
