import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
// import interactionPlugin from '@fullcalendar/interaction'

export default function Calendar ( props ) {

    const { events } = props
    const [ eventsList, setEventsList ] = useState([])

    const eventTimeFormat = {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: 'short'
    }

    useEffect(() => {
        let eventsArray = []

        events.forEach( event => {

            if ( event?.activity ) {
                const calendarObject = {
                    title: event.activity.activityName,
                    start: event.startDate,
                    end: event.endDate
                }

                eventsArray.push( calendarObject )
            }
        } )

        setEventsList( eventsArray )
    }, [ events, setEventsList ])

    function renderEventContent(eventInfo) {
        return (
          <div className="calendar__item">
            <span className="calendar__time">{eventInfo.timeText}</span>
            <span className="calendar__title">{eventInfo.event.title}</span>
          </div>
        )
    }

    return (
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView='dayGridMonth'
            weekends={true}
            displayEventEnd={true}
            events={eventsList}
            eventTimeFormat={eventTimeFormat}
            firstDay={1}
            fixedWeekCount={false}
            height={"auto"}
            stickyHeaderDates={false}
            buttonText={{today: 'Today'}}
            eventContent={renderEventContent} // custom render function
        />
    )
}

Calendar.propTypes = {
    events: PropTypes.array.isRequired
}