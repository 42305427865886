import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Anchor from '../Anchor/Anchor'
import Calendar from './Calendar'
import EventsList from './EventsList'

export default function Events ( props ) {

    const { events } = props
    const [ listView, setListView ] = useState( true )

    return (
        <section className="events">
            <Anchor anchorId="whats-on" />
            <div className="events__wrapper wrapper">
                <h2 className="events__heading">What's on at the <span>Hexagon</span></h2>

                <div className="events__container">
                    <div className="events__buttons">
                        <button className={`events__button ${ listView ? 'active' : ''}`} onClick={ () => setListView( true ) }>List View</button>
                        <button className={`events__button ${ listView ? '' : 'active'}`} onClick={ () => setListView( false ) }>Calendar View</button>
                    </div>

                    <div className={`events__tab-container events__tab-container--list ${ listView ? 'active' : ''}`}>
                        <EventsList events={events} />
                    </div>
                    <div className={`events__tab-container events__tab-container--calendar ${ listView ? '' : 'active'}`}>
                        <Calendar events={events} />
                    </div>
                </div>
            </div>
        </section>
    )
}

Events.propTypes = {
    events: PropTypes.array.isRequired
}