import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

export default function EventsList ( props ) {
    const { events } = props
    const [ eventsList, setEventsList ] = useState([])
    const [ monthSelected, setMonth ] = useState('0')

    useEffect(() => {
        const today = new Date().setUTCHours(0, 0, 0, 0);
        // Filter out events from previous months
        const upcomingEvents = events.filter( e => new Date(e.startDate).setUTCHours(0, 0, 0, 0) >= today );
        let monthsArray = [];

        upcomingEvents.forEach( event => {

            // If an activity hasn't been assigned, ignore and move to next event
            if ( !event.activity ) {
                return;
            }

            const month = moment( event.startDate ).format('MMMM')
            const day = moment( event.startDate ).format('DD')
            const monthFound = monthsArray.find( item => item.month === month )

            if ( monthFound ) {
                const dayFound = monthFound.days.find( item => item.day === day )

                if ( dayFound ) {
                    dayFound.activities.push( event )
                } else {
                    const dayObject = {
                        day,
                        weekday: moment( event.startDate ).format('ddd'),
                        monthAbbr: moment( event.startDate ).format('MMM'),
                        activities: [ event ]
                    }

                    monthFound.days.push( dayObject )
                }
            } else {
                const monthObject = {
                    month,
                    days: [
                        {
                            day,
                            weekday: moment( event.startDate ).format('ddd'),
                            monthAbbr: moment( event.startDate ).format('MMM'),
                            activities: [ event ]
                        }
                    ]
                }

                monthsArray.push( monthObject )
            }
        } )

        setEventsList( monthsArray )

    }, [ events, setEventsList ])

    return (
        <>
            <div className="events__filters-container">
                <div className="events__filter-label">Filter by month: </div>
                <div className="events__filter">
                    <select
                        className="events__filter-dropdown"
                        onChange={ e => setMonth( e.target.value ) }
                        defaultValue="0">
                        { eventsList?.map( (month, index) => (
                            <option value={ index } key={ index }>{ month.month }</option>
                        ) ) }
                    </select>
                </div>
            </div>

            { eventsList?.map( (month, index) => (
                <div className={`events__month-container ${ monthSelected === index.toString() ? 'active' : '' }`} key={ index }>
                    { month.days.map( (day, index) => (
                        <div className="events__date-container" key={ index }>
                            <div className="events__date">
                                <span className="events__date-weekday">{ day.weekday }</span>
                                <span className="events__date-day">{ day.day }</span>
                                <span className="events__date-month">{ day.monthAbbr }</span>
                            </div>
                            <div className="events__events-list">
                                { day.activities?.map( activity => (
                                    <div className="events__event-item" key={ activity.strapi_id }>
                                        <div className="events__event-time">{ moment( activity.startDate ).format( 'HH:mm' ) } - {  moment( activity.endDate ).format( 'HH:mm' ) }</div>
                                        <div className="events__event-name">
                                            { activity.activity.activityName }

                                            { activity.activity?.privateGroup &&
                                                <> (Private Group)</>
                                            }

                                            { activity.activity?.additionalInfo &&
                                                <span className="events__event-info">{ activity.activity.additionalInfo }</span>
                                            }
                                        </div>
                                        { ( activity.activity?.contactNumber || activity.activity?.contactEmail ) &&
                                            <div className="events__event-contact">
                                                { activity.activity?.privateGroup ? (
                                                    <>
                                                        Contact The Hexagon:
                                                        { activity.activity?.contactNumber &&
                                                            <a className="events__event-contact-number" href={`tel:${ activity.activity?.contactNumber }`}>{ activity.activity?.contactNumber }</a>
                                                        }
                                                        { activity.activity?.contactEmail &&
                                                            <a className="events__event-contact-number" href={`mailto:${ activity.activity?.contactEmail }`}>{ activity.activity?.contactEmail }</a>
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        Contact { activity.activity?.contactName }:
                                                        { activity.activity?.contactNumber &&
                                                            <a className="events__event-contact-number" href={`tel:${ activity.activity?.contactNumber }`}>{ activity.activity?.contactNumber }</a>
                                                        }
                                                        { activity.activity?.contactEmail &&
                                                            <a className="events__event-contact-number" href={`mailto:${ activity.activity?.contactEmail }`}>{ activity.activity?.contactEmail }</a>
                                                        }
                                                    </>
                                                ) }
                                            </div>
                                        }
                                        {/* <div className="events__event-price">{ activity.eventPrice }</div> */}
                                    </div>
                                ) ) }
                            </div>
                        </div>
                    ) ) }
                </div>
            ) ) }
        </>
    )
}

EventsList.propTypes = {
    events: PropTypes.array.isRequired
};