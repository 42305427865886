import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import RoomThumbnails from './RoomThumbnails'

export default function ProductImages( props ) {

    const { images } = props

    const [ state, setState ] = useState({
        mainImage: images[ 0 ],
        currentIndex: 0
    })
    const [ modalActive, setModalActive ] = useState()

    const toggleImageModal = () => {

        if ( !modalActive ) {
            const newScrollPosition = window.pageYOffset

            document.body.style.top = `-${newScrollPosition}px`
            document.body.classList.add('u-body-no-scroll')

            setModalActive( true )
        } else {
            const newScrollPosition = document.body.getBoundingClientRect().top

            document.body.classList.remove('u-body-no-scroll');
            document.body.style.removeProperty('top')

            window.scrollTo(0, Math.abs( newScrollPosition ))
            setModalActive( false )
        }
    }

    return (
        <div className="room-images">
            <div className="room-images__main">
                <div className="room-images__main-container" onClick={ () => toggleImageModal() } onKeyDown={ e => { if ( e.keyCode === 13 || e.keyCode === 32 ) toggleImageModal() } } role="button" tabIndex={ 0 }>
                    <GatsbyImage image={ state.mainImage.localFile.childImageSharp.gatsbyImageData } className="room-images__main-image" objectFit="contain" alt={ state.mainImage.caption } />
                    <span className="room-images__image-caption">{ state.mainImage.caption }</span>
                </div>
            </div>

            <RoomThumbnails images={ images } state={ state } setState={ setState } modalActive={ modalActive } toggleImageModal={ toggleImageModal } />
        </div>
    )
}

ProductImages.propTypes = {
    images: PropTypes.array
}